<template>
  <!-- 商务合作 -->
    <div>
      <!-- 面包屑导航区域 这个组件的样式其他组件也用的到 所以写到公共样式里 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> <!-- 点击回到welcome 因为重定向 -->
        <el-breadcrumb-item>服务管理</el-breadcrumb-item>
        <el-breadcrumb-item>商务合作</el-breadcrumb-item>
      </el-breadcrumb>
  
      <!-- 卡片视图区域 -->
      <el-card>
        <!-- layout栅格 设置多少行多少列 span是宽度 gutter是间隙 -->
        <el-row :gutter="20">
          <el-col :span="8">
            <!-- 搜索与添加区域 -->
            <el-input placeholder="请输入内容" v-model="queryInfo.name" clearable>
              <!-- 将value和query双向绑定 点击搜索就发送请求 请求相应的数据 放到userlist里 如果默认不搜索就是请求所有的数据 同时加上clearble属性 使得点击可以清除输入内容 于是触发clear事件 监听事件让下面显示所有数据 -->
              <el-button slot="append" icon="el-icon-search" @click="getCooperationList"></el-button>
            </el-input>
          </el-col>
        </el-row>
  
        <!-- 用户列表区域 -->
        <el-table :data="userlist" border stripe> <!-- 跟menu一样 把要展示的数据存储到table自带的属性data里面 下面再用prop取对应的数据 和v-model双向绑定 -->
          <el-table-column type="index"></el-table-column> <!-- column索引列 只要加上type="index" -->
          <el-table-column label="姓名" prop="linkman"></el-table-column>
          <el-table-column label="单位" prop="unit"></el-table-column>
          <el-table-column label="电话" prop="phone"></el-table-column>
          <el-table-column label="地区" prop="province">
            <template slot-scope="scope"> <!-- 作用域插槽 -->
           <div>{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}</div>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark"></el-table-column>
          <el-table-column label="是否合作">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.disposeState == '1'">同意添加</el-tag>
            <el-tag type="danger" v-else>{{ scope.row.disposeState == '0'?"未处理": scope.row.disposeState == '2'?"拒绝":"停止合作"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否采纳状态">
          <template slot-scope="scope"> <!-- 作用域插槽 新版是v-slot="scope" scope.row代表这一行的所有数据 这是从上面userlist里拿出来的 -->
            <el-switch v-model="scope.row.relation" :active-value="1" :inactive-value="0"
              @change="amendRelation(scope.row)"> <!-- 将开关的状态和数据里的mg_state绑定 -->
            </el-switch>
          </template>
        </el-table-column>
        
          <el-table-column label="提交时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="scope"> <!-- 作用域插槽 -->
              <!-- 删除按钮 -->
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)"></el-button>
              <el-popconfirm style="margin-left: 10px;" title="这是一段内容确定删除吗？" @cancel="ToExamine(scope.row,2)"  @confirm="ToExamine(scope.row,1)" confirm-button-text="同意合作" cancel-button-text="拒绝合作">
                <el-button slot="reference" icon="el-icon-setting" size="mini" type="warning"></el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
            <!-- 审核状态 -->

            
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.page" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total"> <!-- layout表示显示哪些东西 -->
        </el-pagination>
  
      </el-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: '',
        queryInfo: {
          name: "",
          page: 1,
          name: "",
          pageSize: 10
        },
        userlist: [],
        total: 0,
      }
    },
    created() {
      this.getCooperationList()
    },
    methods: {
       // 监听 switch 开关状态的改变 调用put请求把原数据更改 这样每次刷新页面时就能保存这个状态 相当于把这个状态存储起来了（原数据存储的方式）
    async amendRelation(item) {
      console.log(item);
console.log(item.relation);
console.log(item.relation==1);
      const { data: res } = await this.$http.get('/Cooperation/amendRelation', {
        params: {
          id: item.id,
          relation:item.relation ==0?0:1
        }
      })
      if (res.code != 1) { /* 原数据修改失败 页面的状态重置回去（保持跟原数据一样 刷新也行） */
        return this.$message.error('更新用户状态失败！')
      }
      this.$message.success('更新用户状态成功！');
      this.getCooperationList()
    },
      async ToExamine(item,disposeState){
        const { data: res } = await this.$http.get('/Cooperation/checkCooperation', {
          params: {
            id:item.id,
            disposeState:disposeState
          }
        })
        console.log(res);
        if(res.code == 1){
          this.getCooperationList();
          return this.$message.success(res.data)
        }
        return this.$message.error(res.msg)
      },

      // --------请求方法-----
      async getCooperationList() {
        console.log(this.queryInfo);
        const { data: res } = await this.$http.get('/Cooperation/getCooperationList', {
          params: this.queryInfo
        })
        if (res.code != 1) {
          return this.$message.error('获取列表失败！')
        }
        this.userlist = res.data.records;
        console.log(res.data);
        this.total = res.data.total;
        console.log(this.userlist)
      },
      // 监听 下拉页码 改变的事件 newsize为选择的条数 选择了几条就把这个作为参数传给数据请求中重新请求
      handleSizeChange(newSize) {
        // console.log(newSize)
        this.queryInfo.pageSize = newSize
        this.getCooperationList()
      },
      // 监听 页码值 改变的事件 newPage为选择的页码值 选择了第几页就把这个页码作为参数传给数据请求中重新请求
      handleCurrentChange(newPage) {
        console.log(newPage)
        this.queryInfo.page = newPage
        this.getCooperationList()
      },
      // 监听 搜索按钮 点击事件
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize
        this.getCooperationList()
      },
      // 根据Id删除对应的用户信息
      async removeUserById(id) {
        // 弹框询问用户是否删除数据（参见element）
        const confirmResult = await this.$confirm( /* 先给vue挂载了$confirm函数 里面的参数代表弹框显示的内容样式 函数的返回值是promise 所以可以用asyc和await来优化 这样返回的值即confirmResult就是一个字符串了（之前的是一个数据）如果确定就是confirm 取消就是cancle 由catch捕获 */
          '此操作将永久删除该商家, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).catch(err => err)
        if (confirmResult !== 'confirm') {
          return this.$message.info('已取消删除')
        }
        const { data: res } = await this.$http.get('/Cooperation/delete', {
          params: {
            id: id
          }
        })
        if (res.code != 1) {
          return this.$message.error('删除商务合作失败！')
        }
        this.$message.success('删除商务合作成功！');
        this.getCooperationList()
      },
    }, 
  }
  </script>
  <style lang="less" scoped>
    </style>